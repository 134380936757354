body {
  background-image: url(/images/chalkboard.jpg);
  background-attachment: fixed;
  margin: 0;
}

#header {
  background-color: #8C1C2F;
  width: 100%;
  height: 100px;
  padding: 10px;
}

#headerText {
  margin-left: 130px;
  margin-top: -90px;
}

#menuBar {
  height: 20px;
  width: 100%;
  padding: 10px;
  background: #000000; /* Show a solid color for older browsers */
  background: -moz-linear-gradient(#000000, #888888, #000000);
  background: -o-linear-gradient(#000000, #888888, #000000);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#000000), to(#888888)); /* Older webkit syntax */
  background: -webkit-linear-gradient(#000000, #888888, #000000);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#888888'); /* for IE */
}

#main {
  padding: 6px;
}

.container {
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px 0 #444444;
  box-shadow: 0 5px 10px 0 #444444;
  background-color: white;
  border: 1px solid #444444;
  display: inline-block;
  margin: 3px;
  padding: 0;
}

.access_error {
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 10px 10px 0 #C2C2C2;
  box-shadow: 0 10px 10px 0 #C2C2C2;
  background-color: white;
  border: 1px solid #c5c5c5;
  display: inline-block;
  margin: 0;
  padding: 5px;
}

.content {
  background-color: white;
  display: inline-block;
  margin: 20px;
  z-index: 1;
}

.content-menu {
  background-color: white;
  display: inline-block;
  margin: 10px;
  z-index: 1;
}

.contentHeader {
  height: 25px;
}

#inventoryList {

}

/* **************************** */
/* Content text styles          */
/* **************************** */

#header {
  color: white;
}

#main {
  font-family: 'UbuntuBold', sans-serif;
  font-size: 16px;
}
#headerText {
  color: white;
  font-size: 30px;
  font-family: "a_love_of_thunderregular", sans-serif;
}

.contentHeader {
  color: black;
  font-family: "UbuntuBold", sans-serif;
  font-size: 24px;
}

#adminMenuHeader {
  color: black;
  font-family: "UbuntuBold", sans-serif;
  font-size: 18px;
}

#inventoryList {
  color: black;
  font-family: "UbuntuRegular", sans-serif;
  font-size: 18px;
}

.content-menu a {
  color: rgb(220, 95, 4);
  font-family: "UbuntuRegular", sans-serif;
  font-size: 16px;
  text-decoration: none;
}

.content-menu {
  color: rgb(0, 0, 0);
  font-family: "UbuntuRegular", sans-serif;
  font-size: 16px;
  text-decoration: none;
}

.content a {
  color: black;
  font-family: "UbuntuRegular", sans-serif;
  font-size: 18px;
  text-decoration: none;
}

.content {
  color: black;
  font-family: "UbuntuRegular", sans-serif;
  font-size: 16px;
  text-decoration: none;
}

#contentSmall {
  font-family: 'UbuntuRegular', sans-serif;
  font-size: 13px;
}

#contentBoldSmall {
  font-family: 'UbuntuBold', sans-serif;
  font-size: 13px;
}

#access_error {
  color: red;
  font-family: 'UbuntuBold', sans-serif;
  font-size: 16px;
}

#menuBar {
  font-size: 18px;
  font-family: "UbuntuRegular", sans-serif;
  color: white;
}

#menuBar a {
  color: white;
  font-family: "UbuntuRegular", sans-serif;
  font-size: 18px;
  text-decoration: none;
}

/* **************************** */
/* Form Elements                */
/* **************************** */

#submitButton {
  color: white;
  font-family: "UbuntuLight", sans-serif;
  font-size: x-small;
  padding: 3px 8px 3px !important;
  background-color: rgb(49, 152, 62);
  text-shadow: 1px 1px #166c06;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  cursor: pointer;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
}

#show_detailed {
  color: white;
  font-family: "UbuntuLight", sans-serif;
  font-size: x-small;
  padding: 3px 8px 3px !important;
  background-color: rgb(49, 152, 62);
  text-shadow: 1px 1px #166c06;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  cursor: pointer;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
}

#show_summary {
  color: white;
  font-family: "UbuntuLight", sans-serif;
  font-size: x-small;
  padding: 3px 8px 3px !important;
  background-color: rgb(49, 152, 62);
  text-shadow: 1px 1px #166c06;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  cursor: pointer;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
}

#cancelButton {
  color: white;
  font-family: "UbuntuLight", sans-serif;
  font-size: x-small;
  background-color: #aa0010;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  cursor: pointer;
}

.deleteButton {
  color: white;
  font-family: "UbuntuLight", sans-serif;
  font-size: x-small;
  background-color: #aa0010;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  cursor: pointer;
}

.moveButton {
  color: white;
  font-family: "UbuntuLight", sans-serif;
  font-size: x-small;
  background-color: #166c06;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  cursor: pointer;
}

#upperLeftCorner {
  position: absolute;
}

.itemList {
  margin-left: -40px;
  list-style-type: none;
}


/* **************************** */
/* fonts                        */
/* **************************** */

@font-face {
  font-family: 'a_love_of_thunderregular';
  src: url('/fonts/a_love_of_thunder-webfont.eot');
  src: url('/fonts/a_love_of_thunder-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/a_love_of_thunder-webfont.woff') format('woff'),
  url('/fonts/a_love_of_thunder-webfont.ttf') format('truetype'),
  url('/fonts/a_love_of_thunder-webfont.svg#a_love_of_thunderregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'UbuntuRegular';
  src: url('/fonts/Ubuntu-R-webfont.eot');
  src: url('/fonts/Ubuntu-R-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Ubuntu-R-webfont.woff') format('woff'),
  url('/fonts/Ubuntu-R-webfont.ttf') format('truetype'),
  url('/fonts/Ubuntu-R-webfont.svg#UbuntuRegular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'UbuntuItalic';
  src: url('/fonts/Ubuntu-RI-webfont.eot');
  src: url('/fonts/Ubuntu-RI-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Ubuntu-RI-webfont.woff') format('woff'),
  url('/fonts/Ubuntu-RI-webfont.ttf') format('truetype'),
  url('/fonts/Ubuntu-RI-webfont.svg#UbuntuItalic') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'UbuntuBold';
  src: url('/fonts/Ubuntu-B-webfont.eot');
  src: url('/fonts/Ubuntu-B-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Ubuntu-B-webfont.woff') format('woff'),
  url('/fonts/Ubuntu-B-webfont.ttf') format('truetype'),
  url('/fonts/Ubuntu-B-webfont.svg#UbuntuBold') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'UbuntuBoldItalic';
  src: url('/fonts/Ubuntu-BI-webfont.eot');
  src: url('/fonts/Ubuntu-BI-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Ubuntu-BI-webfont.woff') format('woff'),
  url('/fonts/Ubuntu-BI-webfont.ttf') format('truetype'),
  url('/fonts/Ubuntu-BI-webfont.svg#UbuntuBoldItalic') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'UbuntuLight';
  src: url('/fonts/Ubuntu-L-webfont.eot');
  src: url('/fonts/Ubuntu-L-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Ubuntu-L-webfont.woff') format('woff'),
  url('/fonts/Ubuntu-L-webfont.ttf') format('truetype'),
  url('/fonts/Ubuntu-L-webfont.svg#UbuntuLight') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'UbuntuLightItalic';
  src: url('/fonts/Ubuntu-LI-webfont.eot');
  src: url('/fonts/Ubuntu-LI-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Ubuntu-LI-webfont.woff') format('woff'),
  url('/fonts/Ubuntu-LI-webfont.ttf') format('truetype'),
  url('/fonts/Ubuntu-LI-webfont.svg#UbuntuLightItalic') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'UbuntuMedium';
  src: url('/fonts/Ubuntu-M-webfont.eot');
  src: url('/fonts/Ubuntu-M-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Ubuntu-M-webfont.woff') format('woff'),
  url('/fonts/Ubuntu-M-webfont.ttf') format('truetype'),
  url('/fonts/Ubuntu-M-webfont.svg#UbuntuMedium') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'UbuntuMediumItalic';
  src: url('/fonts/Ubuntu-MI-webfont.eot');
  src: url('/fonts/Ubuntu-MI-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Ubuntu-MI-webfont.woff') format('woff'),
  url('/fonts/Ubuntu-MI-webfont.ttf') format('truetype'),
  url('/fonts/Ubuntu-MI-webfont.svg#UbuntuMediumItalic') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'UbuntuCondensedRegular';
  src: url('/fonts/Ubuntu-C-webfont.eot');
  src: url('/fonts/Ubuntu-C-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Ubuntu-C-webfont.woff') format('woff'),
  url('/fonts/Ubuntu-C-webfont.ttf') format('truetype'),
  url('/fonts/Ubuntu-C-webfont.svg#UbuntuCondensedRegular') format('svg');
  font-weight: normal;
  font-style: normal;

}


